import React from 'react';
import '../form.scss';

export const upload = (field) => {

   const files = field.input.value;

    return (
        <div className={field.containerClass ? field.containerClass : "field-group"}>
            {field.label ? (<label className={field.labelClass} >{field.label}</label>) : ''}
            <div className="form-input-container">
                <input 
                    type="file" 
                    multiple={field.multiple ? field.multiple : false}
                    accept={field.accept ? field.accept : '.jpg, .png, .jpeg'} 
                    onChange={( filesToUpload, e ) => field.input.onChange(filesToUpload)} 
                    disabled={field.disabled}
                />
                <span className="form-error">{field.meta.touched && field.meta.error ? field.meta.error : ''}</span>
            </div>
            <div className="container-row uploaded_images">
                {files && Array.isArray(files) && (
                    <ul>
                    { files.map((file, i) => <li key={i}>{file.name}</li>) }
                    </ul>
                )}
            </div>
        </div>
    );
};
